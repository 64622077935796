import React from "react"
import "../sass/main.scss"
import {
  Layout,
  SEO,
  HeadSection,
  ImageSection,
  Scrollable,
} from "../components"
import { useStaticQuery, graphql, Link } from "gatsby"
import { Row, Col, Container } from "react-bootstrap"

const AboutPage = () => {
  const headTitle = (): string => {
    return "About us"
  }
  const headSubTitle = (): string => {
    return `Experts in enterprise AI`
  }
  const headDescription = (): string => {
    return `<p>Satalia’s founder and CEO, Dr Daniel Hulme, has over two decades of experience researching and implementing AI systems. Satalia’s co-founders include Dr Alastair Moore, an expert in Machine Learning and Innovation, and Professor Anthony Finkelstein, who is the Chief Scientific Adviser for National Security to the HM Government. For over a decade, Satalia has built machine learning and optimisation into adaptive, scalable AI systems.</p>`
  }
  const imageDescription = () => {
    return (
      <Col
        xs="3"
        sm="7"
        md="8"
        lg="9"
        className="z-index-2 mb-150 offset-lg-2 offset-md-2 offset-sm-1"
      >
        <div className="st-block st-block--transparent st-block--no-padding">
          <h3>
            We have grown organically to 140+ people, distributed across the
            globe. We are one of a handful of companies that truly understand
            AI,{" "}
            <span className="font-weight-medium">
              and regularly keynote on AI and its impact on humanity.
            </span>
          </h3>
        </div>
      </Col>
    )
  }

  const imageContent = () => {
    return (
      <Row>
        <Col xs="3" sm="8" md="10" lg="12" xl="12">
          <p className="font-weight-medium mb-32">
            Satalia leverages its unique talent and proprietary technologies to
            build innovations for some of the world’s largest organisations,
            including Tesco and PwC.
          </p>
          <p>
            Satalia is a thought-leader in AI, pioneering the future of work by
            combining technology with organisational psychology to create
            swarm-like organisations.
          </p>
          <br />
          <p className="mb-32">
            We are purpose driven, and want to work with clients, partners and
            associates to help solve some of the biggest challenges facing
            humanity.
          </p>
        </Col>
      </Row>
    )
  }
  // Add or update images under images/about folder in .svg format while keeping the same name format [number]_[name].svg.
  const data: any = useStaticQuery(graphql`
    query {
      about: allFile(
        filter: { relativeDirectory: { eq: "about" } }
        sort: { fields: name }
      ) {
        edges {
          node {
            name
            publicURL
          }
        }
      }
      european: file(relativePath: { eq: "european-dark.svg" }) {
        publicURL
      }
    }
  `)
  return (
    <Layout step={2} darkMode={true}>
      <SEO
        title="About | Experts in Enterprise AI"
        description="Satalia has decades of experience in AI, and is a leading thinker and developer of enterprise AI technologies."
        twitterTitle="About | Experts in Enterprise AI"
        twitterDescription="Satalia has decades of experience in AI, and is a leading thinker and developer of enterprise AI technologies."
        openGraphTitle="About | Experts in Enterprise AI"
        openGraphDescription="Leading thinkers and developers of enterprise AI technology."
      />
      <Container>
        <section id="about" className="mb-25">
          <HeadSection
            title={headTitle()}
            subtitle={headSubTitle()}
            description={headDescription()}
          ></HeadSection>
        </section>
        <section id="about-information" className="mb-285">
          <ImageSection
            description={imageDescription()}
            content={imageContent()}
            image="about"
            align="end"
            aos="fade-right"
          ></ImageSection>
        </section>
      </Container>
      <Container fluid>
        <section id="professionals" className="mb-300">
          <Row>
            <Container>
              <Row className="justify-content-center">
                <Col xs="3" sm="6" md="8" lg="10" xl="8">
                  <div className="st-block st-block--transparent mb-21">
                    <h3 className="p-0">
                      We augment our expertise through alliances with leading
                      organisations across
                      <span className="font-weight-medium">
                        {" "}
                        the professional services, retail and technology
                        sectors.
                      </span>
                    </h3>
                  </div>
                </Col>
              </Row>
            </Container>
          </Row>

          <Row className="justify-content-center justify-content-md-right">
            <Col xs="3" sm="8" md="10" lg="12" xl="12">
              <Scrollable
                section="#professionals"
                itemSelector=".professional__item"
              >
                <Row className="professional__items justify-content-center st-scroll">
                  <div className="scroll-padding"></div>

                  {data.about.edges.map((file, index) => (
                    <div className="professional__item" key={"case-" + index}>
                      <div className="st-block">
                        <img src={file.node.publicURL} alt={file.node.name} />
                      </div>
                    </div>
                  ))}
                  <div className="scroll-padding"></div>
                </Row>
              </Scrollable>
            </Col>
          </Row>
        </section>
        <section id="economy" className="mb-285">
          <Scrollable
            itemSelector=".economy__item"
            section="#economy"
            leftIndex={0}
          >
            <Row className="economy__items st-scroll">
              <div className="scroll-padding"></div>
              <div className="economy__item">
                <div className="economy__step">
                  <p>Today</p>
                </div>
                <div className="st-block economy__divider-area economy__divider-area--first ">
                  <div className="economy__divider"></div>
                </div>
                <div className="st-block">
                  <Row>
                    <Col
                      xs="3"
                      sm="8"
                      md="10"
                      lg="12"
                      className="economy__item-header"
                    >
                      <h3 className="">Free from</h3>
                      <h3 className="font-weight-medium">inefficiency</h3>
                    </Col>
                    <Col
                      xs="3"
                      sm="8"
                      md="10"
                      lg="12"
                      className="economy__item-content"
                    >
                      <p>
                        We build technologies that radically improve the
                        operational efficiency, customer and employee
                        satisfaction of our clients. We do this through product
                        development and bespoke AI services.
                      </p>
                    </Col>
                  </Row>
                </div>
              </div>
              <div className="economy__item">
                <div className="economy__step">
                  <p className="d-sm-none">Today</p>
                </div>
                <div className="st-block economy__divider-area">
                  <div className="economy__divider"></div>
                </div>
                <div className="st-block">
                  <Row>
                    <Col
                      xs="3"
                      sm="8"
                      md="10"
                      lg="12"
                      className="economy__item-header"
                    >
                      <h3 className="">Free from</h3>
                      <h3 className="font-weight-medium">ineffectiveness</h3>
                    </Col>
                    <Col
                      xs="3"
                      sm="8"
                      md="10"
                      lg="12"
                      className="economy__item-content"
                    >
                      <p>
                        We're using technology, and concepts like organisational
                        swarms and decentralisation to redesign how
                        organisations operate. Using ourselves as a testbed,
                        we're building the foundations for a new, more effective
                        organisational structure that anyone can use.
                      </p>
                    </Col>
                  </Row>
                </div>
              </div>
              <div className="economy__item">
                <div className="economy__step">
                  <p>Future</p>
                </div>
                <div className="st-block economy__divider-area">
                  <div className="economy__divider"></div>
                </div>
                <div className="st-block">
                  <Row>
                    <Col
                      xs="3"
                      sm="8"
                      md="10"
                      lg="12"
                      className="economy__item-header"
                    >
                      <h3 className="">Free to</h3>
                      <h3 className="font-weight-medium">consume</h3>
                    </Col>
                    <Col
                      xs="3"
                      sm="8"
                      md="10"
                      lg="12"
                      className="economy__item-content"
                    >
                      <p>
                        Through efficient automation and the effective use of
                        resources, we'll enable ourselves and others to build a
                        society with abundant healthcare, education, nutrition
                        and shelter. We aspire to make goods and services so
                        accessible that they become free to consume.
                      </p>
                    </Col>
                  </Row>
                </div>
              </div>
              <div className="economy__item">
                <div className="economy__step">
                  <p className="d-sm-none">Future</p>
                </div>
                <div className="st-block economy__divider-area economy__divider-area--last ">
                  <div className="economy__divider"></div>
                </div>
                <div className="st-block">
                  <Row>
                    <Col
                      xs="3"
                      sm="8"
                      md="10"
                      lg="12"
                      className="economy__item-header"
                    >
                      <h3 className="">Free to</h3>
                      <h3 className="font-weight-medium">contribute</h3>
                    </Col>
                    <Col
                      xs="3"
                      sm="8"
                      md="10"
                      lg="12"
                      className="economy__item-content"
                    >
                      <p>
                        Through the creation of an efficient, effective and
                        automated society, where our basic needs are met, we’ll
                        provide everyone with the freedom to pursue what is
                        meaningful to them.
                      </p>
                    </Col>
                  </Row>
                </div>
              </div>
              <div className="scroll-padding"></div>
            </Row>
          </Scrollable>
        </section>
      </Container>
      <Container>
        <section id="european-union" className="mb-285">
          <Row>
            <Col
              xs="3"
              sm="4"
              md="3"
              lg="3"
              xl="3"
              className="offset-xl-2 offset-lg-2 offset-md-1"
            >
              <div className="st-block">
                <img src={data.european.publicURL} />
              </div>
            </Col>
            <Col xs="3" sm="4" md="5" lg="5" xl="5">
              <div className="st-block d-flex justify-content-center flex-column">
                <p>
                  We're co-funding a project with the European Union to build a
                  routing product that optimises same-day deliveries.
                </p>
                <Link
                  to="/eu-fund"
                  className="learn-more"
                  title="European Union"
                >
                  Learn more
                </Link>
              </div>
            </Col>
          </Row>
        </section>
      </Container>
    </Layout>
  )
}

export default AboutPage
